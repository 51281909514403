import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: 'adc47c3f-5b5c-4ec4-b1f2-6525cb0f47be',
    authority: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb',
    redirectUri: 'https://awsivrpru.accenture.com', // Replace with your redirect URI
    responseType: 'token',
    scopes: [
      'openid'
    ]
  },

  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};
