import React from "react";
import { Select, Typography, Flex } from "antd";
import "./reportDropdown.css";
import { useConnectionManager } from "../provider/connectionManagerProvider";

const { Option } = Select;
const { Text } = Typography;

const ReportDropdown = () => {
  const { setReportType } = useConnectionManager();

  const handleChange = (value) => {
    if (value === "Global Solution") {
      console.log("Global Solution");
      setReportType("globalSolution");
    } else {
      setReportType("indiaSolution");
      console.log("India Solution");
    }
  };

  return (
    <>
      <div className="pru-dropdown">
        <Flex vertical>
          <Text strong>Select which Reports Page you want to view here:</Text>

          <Select onChange={handleChange} defaultValue="Global Solution" style={{ width: "300px" }}>
            <Option value="Global Solution">Global Solution</Option>
            <Option value="India Solution">India Solution</Option>
          </Select>
        </Flex>
      </div>
    </>
  );
};
export default ReportDropdown;
