

export function getReportColumns() {
    return [
        {
            title: 'Contact Id',
            dataIndex: 'ContactId',
            key:'ContactId'
        },
        {
            title: 'Unique Serial',
            dataIndex: 'UniqueSerial',
            key:'UniqueSerial'
        },
        {
            title: 'Sub Unique Serial',
            dataIndex: 'SubUniqueSerial',
            key:'SubUniqueSerial'
        },
        {
            title: 'AWS DID Number',
            dataIndex: 'AWSDIDNumber',
            key:'AWSDIDNumber'
        },
        {
            title: 'Start Time',
            dataIndex: 'StartTime',
            key:'StartTime'
        },
        {
            title: 'End Time',
            dataIndex: 'EndTime',
            key:'EndTime'
        },
        {
            title: 'Duration',
            dataIndex: 'Duration',
            key:'Duration'
        },
        {
            title: 'FunctionType',
            dataIndex: 'FunctionType',
            key:'FunctionType'
        },
        {
            title: 'Sub Function Type',
            dataIndex: 'SubFunctionType',
            key:'SubFunctionType'
        },
        {
            title: 'Last Point Of Completion',
            dataIndex: 'LastPointOfCompletion',
            key:'LastPointOfCompletion'
        },
        {
            title: 'INC Number',
            dataIndex: 'INCNumber',
            key:'INCNumber'
        },
        {
            title: 'Country',
            dataIndex: 'Country',
            key:'Country'
        }
    ]
}

export function getWeeklyReportColumns () {
    return [
        {
            title: 'Files',
            dataIndex: 'Files',
            key: 'Files'
        },
        {
            title: 'Size',
            dataIndex: 'Size',
            key: 'Size'
        },
    ]
}