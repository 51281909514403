import React, { useContext, useState } from "react";


const ConnectionManagerStateContext = React.createContext(null);

export function useConnectionManager() {
    const state = useContext(ConnectionManagerStateContext);
  
    if (!state) {
      throw new Error("useConnectionManager must be used within ConnectionManagerProvider");
    }
    return state;
  }

  export function ConnectionManagerProvider({ children }) {
    const [reportType, setReportType] = useState('globalSolution')

    const providerValue = {
        reportType,
        setReportType
    }
    return <ConnectionManagerStateContext.Provider value={providerValue}>{children}</ConnectionManagerStateContext.Provider>
  }