import React, { useEffect, useState, useRef } from "react";
import { Tabs, Typography, DatePicker, Row, Col, Button, Flex } from "antd";
import "./tabReportType.css";
import moment from "moment/moment";
import TableReportTemplate from "../table/table";
import { getAllGlobalData, getAllIndiaData, filteredGlobalData, filteredIndiaData, getDbLogs, filteredGlobalDataAll, filteredIndiaDataAll } from "../service/getReportsService";
import { useConnectionManager } from "../provider/connectionManagerProvider";
import { Excel } from "antd-table-saveas-excel";
import { getReportColumns } from "../table/tableComponents/tableReportColumns";

const { TabPane } = Tabs;
const { Text } = Typography;

const TabReportType = () => {
  const [type, setType] = useState("report");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateFin, setStartDateFin] = useState("");
  const [endDateFin, setEndDateFin] = useState("");
  const [startDateIndia, setStartDateIndia] = useState("");
  const [endDateIndia, setEndDateIndia] = useState("");
  const [dataSourceGlobal, setDataSourceGlobal] = useState([]);
  const [dataSourceIndia, setDataSourceIndia] = useState([]);
  const [pageGlobal, setPageGlobal] = useState(1);
  const [pageIndia, setPageIndia] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filteredIndia, setFilteredIndia] = useState(false);
  const [filteredGlobalAll, setFilteredGlobalAll] = useState([]);
  const [filteredIndiaAll, setFilteredIndiaAll] = useState([]);

  const isInitialMount = useRef(true);

  const { reportType } = useConnectionManager();

  useEffect(() => {
    async function initialLoad() {
      console.log("Initial Load");
      setLoading(true);
      try {
        let dataSourceGlobalData = await getAllGlobalData(pageGlobal);
        let dataSourceIndiaData = await getAllIndiaData(pageIndia);
        try {
          await getDbLogs();
        } catch (error) {
          console.log(error);
        }
        if (dataSourceGlobalData && dataSourceIndiaData) {
          setDataSourceGlobal(dataSourceGlobalData);
          setDataSourceIndia(dataSourceIndiaData);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
    initialLoad();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isInitialMount.current === true) {
      isInitialMount.current = false;
    } else {
      console.log("filtered");
      fetchData(reportType === "indiaSolution" ? pageIndia : pageGlobal);
    }

    // eslint-disable-next-line
  }, [filtered, pageGlobal, pageIndia, filteredIndia, startDateFin, endDateFin]);

  const disabledNextButton = () => {
    let maxPage;
    let filteredCount;
    let result;
    let totalCount;
    if (reportType === "globalSolution" && type === "report") {
      maxPage = dataSourceGlobal?.body?.rows.length + (pageGlobal - 1) * 10;
      filteredCount = Number(dataSourceGlobal.filteredcount);
      totalCount = Number(dataSourceGlobal.count);
      result = filtered ? (maxPage === filteredCount ? true : false) : maxPage === totalCount ? true : false;
      return result;
    } else if (reportType === "indiaSolution" && type === "report") {
      maxPage = dataSourceIndia?.body?.rows.length + (pageIndia - 1) * 10;
      filteredCount = Number(dataSourceIndia.filteredcount);
      totalCount = Number(dataSourceIndia.count);
      result = filteredIndia ? (maxPage === filteredCount ? true : false) : maxPage === totalCount ? true : false;
      return result;
    } else {
      result = reportType === "globalSolution" ? pageGlobal <= 1 : pageIndia <= 1;
      return result;
    }
  };

  const disabledPrevButton = () => {
    if (reportType === "globalSolution" && type === "report") {
      return pageGlobal <= 1;
    } else if (reportType === "indiaSolution" && type === "report") {
      return pageIndia <= 1;
    } else {
      return reportType === "globalSolution" ? pageGlobal <= 1 : pageIndia <= 1;
    }
  };

  const infoShows = () => {
    if (reportType === "globalSolution" && type === "report") {
      let totalCountGlobal = 0;
      let minPageGlobal = 0;
      let maxPageGlobal = 0;
      let filteredCountGlobal = 0;
      if (filtered === true) {
        if (dataSourceGlobal?.body?.rows.length < 10) {
          minPageGlobal = pageGlobal * 10 - 9;
          maxPageGlobal = dataSourceGlobal?.body?.rows.length + (pageGlobal - 1) * 10;
          filteredCountGlobal = dataSourceGlobal.filteredcount;
          totalCountGlobal = dataSourceGlobal.count;
        } else {
          totalCountGlobal = dataSourceGlobal.count;
          minPageGlobal = dataSourceGlobal?.body?.rows.length * pageGlobal - 9;
          maxPageGlobal = dataSourceGlobal?.body?.rows.length * pageGlobal;
          filteredCountGlobal = dataSourceGlobal?.filteredcount;
        }
      } else {
        totalCountGlobal = dataSourceGlobal?.count || 0;
        minPageGlobal = dataSourceGlobal?.body?.rows.length * pageGlobal - 9 || 0;
        maxPageGlobal = dataSourceGlobal?.body?.rows.length * pageGlobal || 0;
      }
      return filtered === true
        ? `Showing ${minPageGlobal} to ${maxPageGlobal} of ${filteredCountGlobal} Entries (Filtered from ${totalCountGlobal} )`
        : `Showing ${minPageGlobal} to ${maxPageGlobal} of ${totalCountGlobal} entries`;
    } else if (reportType === "indiaSolution" && type === "report") {
      let totalCountIndia = 0;
      let minPageIndia = 0;
      let maxPageIndia = 0;
      let filteredCountIndia = 0;
      if (filteredIndia === true) {
        if (dataSourceIndia?.body?.rows.length < 10) {
          minPageIndia = pageIndia * 10 - 9;
          maxPageIndia = dataSourceIndia?.body?.rows.length + (pageIndia - 1) * 10;
          filteredCountIndia = dataSourceIndia.filteredcount;
          totalCountIndia = dataSourceIndia.count;
        } else {
          totalCountIndia = dataSourceIndia.count;
          minPageIndia = dataSourceIndia?.body?.rows.length * pageIndia - 9;
          maxPageIndia = dataSourceIndia?.body?.rows.length * pageIndia;
          filteredCountIndia = dataSourceIndia?.filteredcount || 0;
        }
      } else {
        totalCountIndia = dataSourceIndia?.count || 0;
        minPageIndia = dataSourceIndia?.body?.rows.length * pageIndia - 9 || 0;
        maxPageIndia = dataSourceIndia?.body?.rows.length * pageIndia || 0;
      }
      return filteredIndia === true
        ? `Showing ${minPageIndia} to ${maxPageIndia} of ${filteredCountIndia} Entries (Filtered from ${totalCountIndia} )`
        : `Showing ${minPageIndia} to ${maxPageIndia} of ${totalCountIndia} entries`;
    }
  };

  useEffect(() => {
    console.log(startDate, startDateIndia, endDate, endDateIndia);
  }, [startDate, startDateIndia, endDateIndia, endDate]);

  const disabledDateStart = (current) => {
    return current > moment();
  };
  const disabledDateEnd = (current) => {
    if (startDate && reportType === "globalSolution") {
      return startDate > current || current > moment();
    } else if (startDateIndia && reportType === "indiaSolution") {
      return startDateIndia > current || current > moment();
    } else {
      return current > moment();
    }
  };
  //Calendar Function

  const onStartDateChange = (dates) => {
    console.log(dates?.$d);
    if (dates) {
      reportType === "globalSolution" ? setStartDate(dates) : setStartDateIndia(dates);
    } else {
      console.log("Clear");
      reportType === "globalSolution" ? setStartDate("") : setStartDateIndia("");
    }
  };

  const onEndDateChange = (dates) => {
    console.log(dates?.$d);
    if (dates) {
      reportType === "globalSolution" ? setEndDate(dates) : setEndDateIndia(dates);
    } else {
      console.log("Clear");
      reportType === "globalSolution" ? setEndDate("") : setEndDateIndia("");
    }
  };

  //Button Function
  const onFilterChange = () => {
    if ((startDate && endDate) || (startDateIndia && endDateIndia)) {
      console.log(startDate, endDate, "API Call");
      if (reportType === "indiaSolution") {
        if (filteredIndia) {
          fetchData(1);
        } else {
          setFilteredIndia(true);
          setPageIndia(1);
          setStartDateFin(startDateIndia);
          setEndDateFin(endDateIndia);
        }
      } else {
        if (filtered) {
          fetchData(1);
        } else {
          setFiltered(true);
          setPageGlobal(1);
          setStartDateFin(startDate);
          setEndDateFin(endDate);
        }
      }
    } else {
      alert("Date Range Filters cannot be blank");
    }
  };

  const onResetChange = () => {
    if (reportType === "indiaSolution") {
      if (filteredIndia) {
        setFilteredIndia(false);
        setStartDateIndia("");
        setEndDateIndia("");
        setPageIndia(1);
      } else {
        setStartDateIndia("");
        setEndDateIndia("");
        setPageIndia(1);
        fetchData(1);
      }
    } else {
      if (filtered) {
        setFiltered(false);
        setStartDate("");
        setEndDate("");
        setPageGlobal(1);
      } else {
        fetchData(1);
        setStartDate("");
        setEndDate("");
        setPageGlobal(1);
      }
    }
  };

  const handleNext = () => {
    if (reportType === "globalSolution") {
      setPageGlobal(pageGlobal + 1);
    } else {
      setPageIndia(pageIndia + 1);
    }
  };

  const handlePrev = () => {
    if (reportType === "globalSolution") {
      setPageGlobal(pageGlobal - 1);
    } else {
      setPageIndia(pageIndia - 1);
    }
  };

  const handleExport = () => {
    const excel = new Excel();
    excel
      .addSheet(reportType === "globalSolution" ? "Global Solution" : "India Solution")
      .addColumns(getReportColumns())
      .addDataSource(reportType === "globalSolution" ? filteredGlobalAll?.body?.rows : filteredIndiaAll?.body?.rows, {
        str2Percent: true,
      })
      .saveAs(fileName());
  };

  const fileName = () => {
    const excelFileName = `40853_DB Logs_${moment().month() + 1}${moment().date()}${moment().year().toString().substr(-2)}.xlsx`;
    return excelFileName;
  };

  async function fetchData(page) {
    if (reportType === "indiaSolution") {
      setLoading(true);
      // let dataSourceIndiaData = filteredIndia === true? await filteredIndiaData(startDateIndia,endDateIndia,page): await getAllIndiaData(page)
      let dataSourceIndiaData;
      if (filteredIndia) {
        if (page === 1) {
          dataSourceIndiaData = await filteredIndiaData(startDateIndia, endDateIndia, page);
          setFilteredIndiaAll(await filteredIndiaDataAll(startDateIndia, endDateIndia));
        } else {
          dataSourceIndiaData = await filteredIndiaData(startDateIndia, endDateIndia, page);
        }
      } else {
        dataSourceIndiaData = await getAllIndiaData(page);
      }
      if (dataSourceIndiaData) {
        setDataSourceIndia(dataSourceIndiaData);
        setLoading(false);
      }
    } else {
      setLoading(true);
      console.log("1");
      // let dataSourceGlobalData = filtered === true? await filteredGlobalData(startDate,endDate, page):await getAllGlobalData(page)
      let dataSourceGlobalData;
      if (filtered) {
        if (page === 1) {
          dataSourceGlobalData = await filteredGlobalData(startDate, endDate, page);
          setFilteredGlobalAll(await filteredGlobalDataAll(startDate, endDate));
        } else {
          dataSourceGlobalData = await filteredGlobalData(startDate, endDate, page);
        }
      } else {
        dataSourceGlobalData = await getAllGlobalData(page);
      }
      if (dataSourceGlobalData) {
        setDataSourceGlobal(dataSourceGlobalData);
        setLoading(false);
      }
    }
  }

  return (
    <div className="pru-tabs">
      <Tabs
        defaultActiveKey="1"
        type="card"
        centered
        size="large"
        onChange={(tab) => {
          console.log(tab);
          setType(tab);
        }}
      >
        <TabPane className="pru-tab-report" tab="Reports" key="report" label="Reports">
          <Row>
            <Col span={11}>
              <Text>Date Range Filter: </Text>
              <DatePicker
                id="startDate"
                allowClear
                onChange={onStartDateChange}
                size="large"
                format={"YYYY-MM-DD"}
                value={reportType === "indiaSolution" ? startDateIndia : startDate}
                disabledDate={disabledDateStart}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    const textStart = document.getElementById("startDate").value;
                    if (textStart.length <= 1) {
                      reportType === "indiaSolution" ? setStartDateIndia("") : setStartDate("");
                    }
                  }
                }}
              />
              <Text> to </Text>
              <DatePicker
                id="endDate"
                allowClear
                onChange={onEndDateChange}
                size="large"
                format={"YYYY-MM-DD"}
                value={reportType === "indiaSolution" ? endDateIndia : endDate}
                disabledDate={disabledDateEnd}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    const textEnd = document.getElementById("endDate").value;
                    if (textEnd.length <= 1) {
                      reportType === "indiaSolution" ? setEndDateIndia("") : setEndDate("");
                    }
                  }
                }}
              />
              <Text> </Text>
              <Button type="primary" size={"large"} className="pru-filter" onClick={onFilterChange}>
                Filter
              </Button>
              <Text> </Text>
              <Button type="primary" size={"large"} className="pru-reset" onClick={onResetChange}>
                Reset
              </Button>
            </Col>
            <Col span={10} push={10}>
              {filtered && reportType === "globalSolution" ? (
                <Button type="primary" size={"large"} onClick={handleExport}>
                  Export to .xlsx
                </Button>
              ) : (
                ""
              )}
              {filteredIndia && reportType === "indiaSolution" ? (
                <Button type="primary" size={"large"} onClick={handleExport}>
                  Export to .xlsx
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row justify={"center"}>
            <TableReportTemplate dataSource={reportType === "globalSolution" ? dataSourceGlobal : dataSourceIndia} type={type} loading={loading} />
          </Row>
        </TabPane>
        <TabPane className="pru-tab-weekly" tab="Weekly Reports" key="weeklyReport" label="Weekly Reports">
          <TableReportTemplate dataSource={[]} type={type} />
        </TabPane>
      </Tabs>
      <Flex justify="space-between" align="center">
        <Flex vertical={false}>
          <Text>{loading ? "" : infoShows()}</Text>
        </Flex>
        <Flex horizontal="true">
          <Button type="text" disabled={disabledPrevButton()} onClick={handlePrev}>
            Previous
          </Button>
          <Button type="text" disabled={disabledNextButton()} onClick={handleNext}>
            Next
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default TabReportType;
