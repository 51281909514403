// import React, {useState} from 'react';
import moment from 'moment/moment';
import axios from 'axios';



export async function getAllGlobalData (page) {

    try {
        const options = {
            headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/',
                    'Content-Type': 'application/json'}
        }
        const response = await axios.post(`https://ew15bwx2q7.execute-api.us-east-1.amazonaws.com/production/reports/lambda`, 
        {"action":"queryData", "newPage":page},
        options)
        
        //  console.log(response.data, "logski")
         return response.data
    } catch (error) {
        console.log('Error fetching global data:', error)
        throw error
    }

}


    export async function getAllIndiaData (page) {

        try {
            const options = {
                headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/',
                        'Content-Type': 'application/json'}
            }
            const response = await axios.post(`https://ew15bwx2q7.execute-api.us-east-1.amazonaws.com/production/reports/lambda`, 
            {"action":"queryCTSDData", "ctsd_newPage":page},
            options)
            
             return response.data
        } catch (error) {
            console.log('Error fetching India data:', error)
            throw error
        }
    
    }

export async function filteredGlobalData (startDate, endDate, newPage) {
    let startDates = moment(startDate.$d).format('YYYY-MM-DD')
    let endDates= moment(endDate.$d).format('YYYY-MM-DD')
    let data
    const options = {
        headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/','Content-Type': 'multipart/form-data'}
    }

    await axios.post(`https://ew15bwx2q7.execute-api.us-east-1.amazonaws.com/production/reports/lambda`, {"action":"filterPage", "startDate":startDates, "endDate": endDates, "newPage":newPage},options).then (res =>{
            data=res.data
         })
         return data
}

export async function filteredGlobalDataAll (startDate, endDate) {
    let startDates = moment(startDate.$d).format('YYYY-MM-DD')
    let endDates= moment(endDate.$d).format('YYYY-MM-DD')
    let data
    const options = {
        headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/','Content-Type': 'multipart/form-data'}
    }

    await axios.post(`https://ew15bwx2q7.execute-api.us-east-1.amazonaws.com/production/reports/lambda`, {"action":"filter", "startDate":startDates, "endDate": endDates},options).then (res =>{
            data=res.data
         })
         return data
}

export async function filteredIndiaData (startDate, endDate, page) {
    let data
    let startDates = moment(startDate.$d).format('YYYY-MM-DD')
    let endDates= moment(endDate.$d).format('YYYY-MM-DD')
    const options = {
        headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/','Content-Type': 'application/json'}
    }
    await axios.post(`https://ew15bwx2q7.execute-api.us-east-1.amazonaws.com/production/reports/lambda`, {"action":"filterPageCTSD", "startDate":startDates, "endDate": endDates, "ctsdPage":page },options).then (res =>{
            console.log(res.data)
            data=res.data
         })
        //  console.log(data)
         return data
}

export async function filteredIndiaDataAll (startDate, endDate) {
    let data
    let startDates = moment(startDate.$d).format('YYYY-MM-DD')
    let endDates= moment(endDate.$d).format('YYYY-MM-DD')
    const options = {
        headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/','Content-Type': 'application/json'}
    }
    await axios.post(`https://ew15bwx2q7.execute-api.us-east-1.amazonaws.com/production/reports/lambda`, {"action":"CTSDfilter", "startDate":startDates, "endDate": endDates},options).then (res =>{
            console.log(res.data)
            data=res.data
         })
        //  console.log(data)
         return data
}

export async function getDbLogs () {

    try {
        const options = {
            headers: {'Access-Control-Allow-Origin': 'https://awsivrpru.accenture.com/',
                    'Content-Type': 'application/json'}
        }
        const response = await axios.get(`https://awsivrpru.accenture.com/DB-Logs.json`,{"_": new Date().getTime()}, 
        options)
        
         return response.data
    } catch (error) {
        console.log('Error fetching DB logs:', error)
        throw error
    }

}