import React from 'react';
import { Table } from 'antd';
import './table.css';
import { getWeeklyReportColumns, getReportColumns } from './tableComponents/tableReportColumns';


const TableReportTemplate = (props) =>{


    // console.log(props)
    let reportColumns
    if (props.type === 'report'){
        reportColumns = getReportColumns()
    } else {
        reportColumns = getWeeklyReportColumns()
    }

    let dataSource = props.dataSource?.body?.rows


    return (
        <div>
            <Table dataSource={dataSource} columns={reportColumns} pagination={false} loading={props.loading}/>

        </div>
    )
}
export default TableReportTemplate